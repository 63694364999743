<template>
  <LoadingRights>
    <div class="fixed h-screen w-full bg-white">
      <div class="fixed top-0 z-30 w-full desktop:h-14">
        <AppHeader />
      </div>
      <div class="overflow-y-auto pt-14 h-full w-full">
        <slot />
      </div>
    </div>
  </LoadingRights>
</template>
